<template>
  <div class="contents-area select-type-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <button
            type="button"
            class="btn btn-short btn-round"
            @click="$router.back()"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.21802 7.83336H13.3327V9.1667H5.21802L8.79402 12.7427L7.85135 13.6854L2.66602 8.50003L7.85135 3.3147L8.79402 4.25736L5.21802 7.83336Z"
                fill="#2D2E3C"
              />
            </svg>
            {{ translations("Back") }}
          </button>
          <Breadcrumbs
            :addItem="{ name: translations('Search'), path: '/search' }"
          />
        </div>
        <div class="select-product-types" v-if="typePurposesLoaded">
          <h1>
            {{
              curCompany.name +
              (curCompany.inn && curCompany.inn !== "-"
                ? " - " + curCompany.inn
                : "") +
              " ( " +
              curCompany.country +
              " )"
            }}
          </h1>
          <div class="product-types-cont">
            <h3>{{ translations("SelectProductType") }}</h3>
            <div class="product-types">
              <div
                class="product-type"
                v-for="item in listPurposeProductTypes"
                :key="item.id"
                @click="chooseProductType(item.id)"
              >
                <div class="title-n-desc">
                  <h4>{{ item.name }}</h4>
                  <div class="description" v-html="item.description"></div>
                </div>
                <div class="price-n-select">
                  <span>{{
                    translations("PriceFromEuro", [item.minPrice])
                  }}</span>
                  <button type="button" class="btn btn-blue btn-short">
                    <span>{{ translations("Select") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "SelectProductType",
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
    };
  },
  computed: {
    ...mapGetters([
      "translations",
      "listPurposeProductTypes",
      "curCompany",
      "listCompanies",
      "typePurposesLoaded",
    ]),
  },
  methods: {
    ...mapActions(["setCurProductType"]),
    async chooseProductType(productTypeId) {
      await this.setCurProductType(productTypeId);
      this.$router.push("/search/select-product");
    },
  },
  created() {
    if (!this.typePurposesLoaded) {
      this.$router.push("/");
    }
  },
};
</script>